import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import Imagen2001pq from '../components/imageComponents/imagen2001pq'
import Imagen5000Esemiautomaticabandejamanualpq from '../components/imageComponents/imagen5000Esemiautomaticabandejamanualpq'
import Imagen5000Esemiautomaticapq from '../components/imageComponents/imagen5000Esemiautomaticapq'
import Imagen5000Esemiautomaticabobinapq from '../components/imageComponents/imagen5000Esemiautomaticabobinapq'
import ImagenPlatoRevolver5080pq from '../components/imageComponents/imagenPlatoRevolver5080pq'

const IndexPage = () => (
  <Layout>
    <SEO title='MÁQUINAS' />
    <Header siteTitle='MÁQUINAS' />
    <Container>

      <div className='row justify-content-md-center'>
          <div className='col-12 col-md-6 text-center'>
            <Link className="maquinas"  to='/2001/'>
              <h3>OR-PRINTER Manual 2001</h3>
              {/* <p> Manual</p> */}
              <Imagen2001pq />
            </Link>
          </div>
          <div className='col-12 col-md-6 text-center'>
            <Link className="maquinas" to='/5000semiautomaticamanual/'>
              <h3>OR-PRINTER Semiautomática con bandeja manual 5010</h3>
              {/* <p> Con Bandeja Manual</p> */}
              <Imagen5000Esemiautomaticabandejamanualpq />
            </Link>
          </div>
      </div>

      <div className='row justify-content-md-center'>
          <div className='col-12 col-md-6 text-center'>
            <Link  className="maquinas" to='/5000semiautomatica/'>
              <h3>OR-PRINTER Semiautomática con bandeja automática 5020</h3>
              {/* <p> Con Bandeja Automática</p> */}
              <Imagen5000Esemiautomaticapq />
            </Link>
          </div>
          <div className='col-12 col-md-6 text-center'>
            <Link className="maquinas"  to='/5000semiautomaticabobina/'>
              <h3>OR-PRINTER automática con bobina 5030</h3>
              {/* <p> Con Bobina</p> */}
              <Imagen5000Esemiautomaticabobinapq />
            </Link>
          </div>
      </div>

      <div className='row justify-content-md-center'>
          <div className='col-12 col-md-6 text-center'>
            <Link  className="maquinas" to='/revolver5080/'>
              <h3>OR-PRINTER Plato revolver 5080</h3>
              {/* <p> Con Bandeja Automática</p> */}
              <ImagenPlatoRevolver5080pq />
            </Link>
          </div>
      </div>


    </Container>
  </Layout>
)

export default IndexPage
